@import "https://fonts.googleapis.com/css2?family=Agdasima:wght@400;700&display=swap";
*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.collapse {
  visibility: collapse;
}

.col-span-2 {
  grid-column: span 2 / span 2;
}

.mx-6 {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mt-1 {
  margin-top: .25rem;
}

.flex {
  display: flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.h-10 {
  height: 2.5rem;
}

.h-12 {
  height: 3rem;
}

.h-20 {
  height: 5rem;
}

.w-16 {
  width: 4rem;
}

.w-20 {
  width: 5rem;
}

.w-full {
  width: 100%;
}

.max-w-7xl {
  max-width: 80rem;
}

.flex-1 {
  flex: 1;
}

.grow {
  flex-grow: 1;
}

.basis-1\/2 {
  flex-basis: 50%;
}

.basis-1\/4 {
  flex-basis: 25%;
}

.basis-2\/5 {
  flex-basis: 40%;
}

.basis-3\/5 {
  flex-basis: 60%;
}

.table-auto {
  table-layout: auto;
}

.border-collapse {
  border-collapse: collapse;
}

.cursor-pointer {
  cursor: pointer;
}

.list-inside {
  list-style-position: inside;
}

.list-disc {
  list-style-type: disc;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-evenly {
  justify-content: space-evenly;
}

.gap-4 {
  gap: 1rem;
}

.place-self-end {
  place-self: end;
}

.place-self-center {
  place-self: center;
}

.rounded-bl-lg {
  border-bottom-left-radius: .5rem;
}

.border {
  border-width: 1px;
}

.border-b-2 {
  border-bottom-width: 2px;
}

.border-solid {
  border-style: solid;
}

.border-black {
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity));
}

.bg-blue-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(30 64 175 / var(--tw-bg-opacity));
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

.bg-gray-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity));
}

.bg-orange-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(234 88 12 / var(--tw-bg-opacity));
}

.bg-red-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(220 38 38 / var(--tw-bg-opacity));
}

.bg-red-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(153 27 27 / var(--tw-bg-opacity));
}

.bg-sky-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(7 89 133 / var(--tw-bg-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-\[url\(\'img\/heart\.png\'\)\] {
  background-image: url("heart.1a86610f.png");
}

.bg-\[url\(\'img\/shield\.png\'\)\] {
  background-image: url("shield.d04daf30.png");
}

.bg-contain {
  background-size: contain;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.p-1 {
  padding: .25rem;
}

.p-12 {
  padding: 3rem;
}

.p-4 {
  padding: 1rem;
}

.p-5 {
  padding: 1.25rem;
}

.p-6 {
  padding: 1.5rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.py-0\.5 {
  padding-top: .125rem;
  padding-bottom: .125rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.pl-8 {
  padding-left: 2rem;
}

.pr-2 {
  padding-right: .5rem;
}

.pr-3 {
  padding-right: .75rem;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.align-middle {
  vertical-align: middle;
}

.font-agdasima {
  font-family: Agdasima;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-5xl {
  font-size: 3rem;
  line-height: 1;
}

.text-6xl {
  font-size: 3.75rem;
  line-height: 1;
}

.text-7xl {
  font-size: 4.5rem;
  line-height: 1;
}

.text-8xl {
  font-size: 6rem;
  line-height: 1;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.font-medium {
  font-weight: 500;
}

.font-semibold {
  font-weight: 600;
}

.uppercase {
  text-transform: uppercase;
}

.italic {
  font-style: italic;
}

.leading-tight {
  line-height: 1.25;
}

.text-neutral-600 {
  --tw-text-opacity: 1;
  color: rgb(82 82 82 / var(--tw-text-opacity));
}

.text-neutral-800 {
  --tw-text-opacity: 1;
  color: rgb(38 38 38 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.shadow-\[0_2px_15px_-3px_rgba\(0\,0\,0\,0\.07\)\,0_10px_20px_-2px_rgba\(0\,0\,0\,0\.04\)\] {
  --tw-shadow: 0 2px 15px -3px #00000012, 0 10px 20px -2px #0000000a;
  --tw-shadow-colored: 0 2px 15px -3px var(--tw-shadow-color), 0 10px 20px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.marker\:mr-2 ::marker {
  margin-right: .5rem;
}

.marker\:mr-2::marker {
  margin-right: .5rem;
}

.even\:bg-gray-200:nth-child(2n) {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

@media print {
  .print\:mx-2 {
    margin-left: .5rem;
    margin-right: .5rem;
  }

  .print\:hidden {
    display: none;
  }

  .print\:h-8 {
    height: 2rem;
  }

  .print\:h-9 {
    height: 2.25rem;
  }

  .print\:h-\[1\.6rem\] {
    height: 1.6rem;
  }

  .print\:h-auto {
    height: auto;
  }

  .print\:min-h-\[3\.5in\] {
    min-height: 3.5in;
  }

  .print\:w-12 {
    width: 3rem;
  }

  .print\:w-8 {
    width: 2rem;
  }

  .print\:w-\[2\.5in\] {
    width: 2.5in;
  }

  .print\:break-inside-avoid {
    break-inside: avoid;
  }

  .print\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .print\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .print\:gap-2 {
    gap: .5rem;
  }

  .print\:bg-gray-200 {
    --tw-bg-opacity: 1;
    background-color: rgb(229 231 235 / var(--tw-bg-opacity));
  }

  .print\:p-3 {
    padding: .75rem;
  }

  .print\:py-1 {
    padding-top: .25rem;
    padding-bottom: .25rem;
  }

  .print\:pl-2 {
    padding-left: .5rem;
  }

  .print\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .print\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  .print\:text-\[0\.7rem\]\/\[1\.25\] {
    font-size: .7rem;
    line-height: 1.25;
  }

  .print\:text-\[0\.7rem\]\/\[1\] {
    font-size: .7rem;
    line-height: 1;
  }

  .print\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .print\:text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
  }

  .print\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .print\:text-black {
    --tw-text-opacity: 1;
    color: rgb(0 0 0 / var(--tw-text-opacity));
  }
}

@media (width >= 640px) {
  .sm\:p-3 {
    padding: .75rem;
  }
}

@media print {
  @media (width >= 640px) {
    .print\:sm\:p-2 {
      padding: .5rem;
    }
  }
}

@media (width >= 768px) {
  .md\:p-4 {
    padding: 1rem;
  }
}

@media (width >= 1024px) {
  .lg\:flex {
    display: flex;
  }

  .lg\:hidden {
    display: none;
  }

  .lg\:w-12 {
    width: 3rem;
  }

  .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .lg\:p-2 {
    padding: .5rem;
  }

  .lg\:p-3 {
    padding: .75rem;
  }

  .lg\:pl-4 {
    padding-left: 1rem;
  }

  .lg\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .lg\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  .lg\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }

  .lg\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}

@media (width >= 1280px) {
  .xl\:flex {
    display: flex;
  }

  .xl\:flex-1 {
    flex: 1;
  }

  .xl\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .xl\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }
}

/*# sourceMappingURL=index.9d80f9db.css.map */
